import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const Verse = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulVerses(sort: { fields: createdAt, order: DESC }, limit: 1) {
        nodes {
          id
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
          source
          content {
            content
          }
        }
      }
    }
  `);
  const { image, source, content } = data.allContentfulVerses.nodes[0];
  const Verseimage = getImage(image);
  return (
    <Section>
      <Container>
        <GatsbyImage image={Verseimage} alt={content.content} />
        <VerseContainer>
          <h3>Verse of the Week</h3>
          <VerseContent>{content.content}</VerseContent>
          <VerseSource>{source}</VerseSource>
        </VerseContainer>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  padding: 1rem;
  background-color: #003f91;
  @media screen and (min-width: 700px) {
    padding: 4rem;
  }
  @media screen and (min-width: 1100px) {
    padding: 6rem;
  }
`;
const Container = styled.div`
  display: flex;
  background-color: #fff;
`;
const VerseContainer = styled.div`
  color: #5d5d5d;
  padding: 1rem;
  padding: 0.25rem;
  @media screen and (min-width: 700px) {
    padding: 1rem;
  }
  h3 {
    color: #4d4d4d;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  }
`;

const VerseContent = styled.p`
  font-size: calc(10px + (30 - 16) * ((100vw - 300px) / (1600 - 300)));
`;

const VerseSource = styled.p`
  font-size: 0.8rem;
  color: #7d7d7d;
  font-size: calc(10px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
`;
