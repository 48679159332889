import React from "react";
import styled from "styled-components";
import background from "../../images/Hero.jpg";
import { Link } from "gatsby";

export const Hero = ({ scroll }) => {
  return (
    <StyledSection>
      <div>
        <h1>Open Session</h1>
        <Link to="/blog">
          <Button>Read Our Blog</Button>
        </Link>
      </div>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  height: 100vh;
  background-image: url(${background});
  background-size: cover;
  background-color: #00000050;
  background-blend-mode: darken;
  background-position: center;
  display: grid;
  justify-content: center;
  align-items: center;

  h1 {
    color: white;
    margin: 1rem;
    font-size: 4rem;
    text-align: center;
  }
  a {
    text-decoration: none;
  }
`;

const Button = styled.button`
  appearance: none;
  background-color: transparent;
  border: 3px solid #fff;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 0 auto;

  &:hover {
    outline: 0;
    background-color: white;
    color: #050505;
    transition: all 0.3s ease-in;
    cursor: pointer;
  }
`;
