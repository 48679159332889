import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

export const Events = ({ reference }) => {
  return (
    <Container ref={reference}>
      <h3>Events</h3>
      <ImageContainer>
        <div className="image-container">
          <StaticImage src="../../images/Paubaya.jpg" alt="Paubaya" />
        </div>
        <div className="image-container">
          <StaticImage src="../../images/declutter.png" alt="declutter" />
        </div>
      </ImageContainer>
    </Container>
  );
};

const Container = styled.section`
  h3 {
    font-size: 48px;
    color: #0f0f0f;
    margin: 1rem;
    text-align: center;
  }
  .image-container {
    max-width: 60%;
    margin: 0 auto;
  }
  margin-bottom: 1rem;
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  margin: 1rem;
  @media screen and (min-width: 800px) {
    margin: 1rem 3rem;
  }
`;
