import * as React from "react";
import Layout from "../components/Layout";
import { Events } from "../components/Sections/Events";
import { Verse } from "../components/Sections/Verse";
import SEO from "../components/Seo";
import { Hero } from "../components/styled/Hero";

// markup
const IndexPage = ({ location }) => {
  const scrollRef = React.useRef(null);
  const handleClick = () =>
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  return (
    <Layout location={location}>
      <SEO title="home" />
      <main>
        <Hero scroll={handleClick} />
        <Verse />
        <Events reference={scrollRef} />
      </main>
    </Layout>
  );
};

export default IndexPage;
